









































































import { Vue, Component, Watch } from "vue-property-decorator";
import assessNav from "@/components/assess/AssessNav.vue";
import { Getter } from "vuex-class";
import { Tabs, TabPane } from "element-ui";
@Component({
    components: {
        "assess-nav": assessNav,
        "el-tabs": Tabs,
        "el-tab-pane": TabPane,
    },
})
export default class AssessList extends Vue {
    @Getter("getAssessPermission") assessPermission: any; //评审权限数组，专家具有哪几轮评审的权限

    eid: string = this.$route.params.eid; //专家id
    review_id: string = this.$route.params.id; //评审轮次id

    /**项目列表的标题,即评测轮次的标题 */
    get assessListTitle() {
        let assessKey = this.assessPermission.findIndex(
            (val: any) => val.review_id == this.review_id
        );
        return this.assessPermission[assessKey].review_name;
    }

    /**项目列表 */
    projectList: { all: any; scoring: any; scored: any } = {
        all: {},
        scoring: {},
        scored: {},
    };

    activeName = "all"; //项目列表导航的active(已评、未评)

    /**项目名,返回系统对应的语言,如没有则返回原文 */
    project_name(el: any) {
        let lang =
            this.$i18n.locale == "en"
                ? "en"
                : this.$i18n.locale == "zh"
                ? "cn"
                : "cn";

        // return el[`name_${lang}`] ? el[`name_${lang}`] : el.name;
        return el.name;
    }

    /**tab点击事件 */
    handleClick() {}

    /**点击项目 */
    handelProject(el: any, item: any, index: any) {
        // console.log(item);

        this.$router.push({
            name: "score",
            params: {
                record_id: String(item.record_id),
                isFinished: item.isFinished ? "1" : "0",
            },
        });
    }

    /**获取项目列表 */
    getListData() {
        const getOpt = this.$api.apiList.getAssessList;
        const opt = getOpt(this.eid, this.review_id);

        this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers,
        }).then((res: any) => {
            this.setListData(res.data.data);
        });
    }

    /**整理项目列表 */
    setListData(list: any) {
        const setList = (list: any, isFinished: boolean) => {
            if (isFinished == false) {
                list.map((item: any) => {
                    let key = "isFinished";
                    let value = false;
                    return (item[key] = value);
                });
            } else {
                list.map((item: any) => {
                    let key = "isFinished";
                    let value = true;
                    return (item[key] = value);
                });
            }
            return list;
        };
        this.projectList.scoring = {
            title: this.$t("scaffold.assess.scoring"),
            list: setList(list.no_finish, false),
        };
        this.projectList.scored = {
            title: this.$t("scaffold.assess.scored"),
            list: setList(list.is_finish, true),
        };
        this.projectList.all = {
            title: this.$t("scaffold.assess.all"),
            list: setList(list.no_finish, false).concat(
                setList(list.is_finish, true)
            ),
        };
    }

    mounted() {
        this.getListData();
    }

    @Watch("$route")
    routeChange(nv: any, ov: any) {
        this.eid = nv.params.eid;
        this.review_id = nv.params.id;
        this.getListData()
    }
}
