















import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class AssessNav extends Vue {
    @Prop({ type: Array, required: true, default: () => [] })
    assessNavList: any;

    actived: any = this.$route.params.id;

    handleNav(item: any, index: any) {
        this.$router.push({
            name: "assessList",
            params: { eid: this.$route.params.eid, id: item.review_id },
        });
    }

    @Watch("$route")
    routerChange(nv: any, ov: any) {
        this.actived = nv.params.id;
    }
}
